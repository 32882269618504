<template>
  <div class="supplyBox">
    <mobileHeader />
    <div class="supplyimg">
      <img src="../../assets/m_supply.jpg" />
      <div
        class="banner-title"
        :class="{ bannerTitleB: $i18n.locale === 'en-US' }"
      >
        {{ $t("lang.supply.bannerTitle") }}
      </div>
    </div>
    <div class="supplcontainer">
      <div class="titles">{{ $t("lang.supply.titles") }}</div>
      <div class="supplyDetail">{{ $t("lang.supply.supplyDetail") }}</div>
      <div class="yini">{{ $t("lang.supply.yini") }}</div>
      <div class="partbox">
        <div class="part">
          <div class="partname">{{ $t("lang.supply.itemOne.partname") }}</div>
          <div class="partContent">
            {{ $t("lang.supply.itemOne.partContent") }}
          </div>
        </div>
        <div class="part">
          <div class="partname">{{ $t("lang.supply.itemTwo.partname") }}</div>
          <div class="partContent">
            {{ $t("lang.supply.itemTwo.partContent") }}
          </div>
        </div>
        <div class="part">
          <div class="partname">{{ $t("lang.supply.itemThree.partname") }}</div>
          <div class="partContent">
            {{ $t("lang.supply.itemThree.partContent") }}
          </div>
        </div>
      </div>
    </div>
    <mobileFooter />
  </div>
</template>

<script>
import mobileHeader from "@/components/mobileHeader";
import mobileFooter from "@/components/mobileFooter";
export default {
  name: "supplyService",
  components: {
    mobileHeader,
    mobileFooter,
  },
};
</script>

<style scoped lang="scss">
.supplyBox {
  .supplyimg {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .banner-title {
      position: absolute;
      font-size: 30px;
      color: #fff;
      font-weight: bold;
    }
    .bannerTitleB {
      font-family: "English-Bold", serif;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .supplcontainer {
    padding: 0 25px;
    margin-top: 50px;
    .titles {
      font-size: 20px;
      font-weight: bold;
    }
    .supplyDetail {
      font-size: 16px;
      color: #6d7276;
      margin-top: 15px;
    }
    .yini {
      font-size: 16px;
      font-weight: bold;
      margin-top: 25px;
    }
  }
  .partbox {
    margin-bottom: 50px;
    .part {
      font-weight: bold;
      margin-top: 50px;
      .partname {
        font-size: 16px;
      }
      .partContent {
        font-size: 23px;
      }
    }
  }
}
</style>
